import { apiRequest } from "@/api";
import { useAdmin } from "@/context/admin";
import { Organization, Subscription } from "@/types/entities";
import { log } from "@/utils/logger";
import { isDefined } from "@/utils/validations";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

const useQueryOrganization = () => {
    const authenticated = useAdmin(state => state.authenticated);
    const { oid } = useParams();
    const enabled = authenticated && isDefined(oid);

    const getOrganizations = async () => {
        try {
            const data = await apiRequest<{
                organization: Organization;
                subscription: Subscription;
            }>("GET", `/organizations/${oid}`);
            return data;
        } catch (e) {
            log.error(e);
        }
    };

    return useQuery({
        queryKey: ["organization", oid],
        queryFn: getOrganizations,
        enabled,
    });
};

export default useQueryOrganization;
