import { styled } from "@mui/material";

export const Styled = {
    Container: styled("div")`
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex: 1;
        ${props => props.theme.breakpoints.down("md")} {
            padding: 20px 0;
        }
    `,
    Brands: styled("ul")`
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(min-content, 200px));
        gap: 20px;
        width: 100%;
        ${props => props.theme.breakpoints.down("sm")} {
            padding: 0;
            margin: 50px auto;
            flex-direction: column;
        }
    `,
    BrandItem: styled("li")`
        border-radius: 8px;
        height: 240px;
        min-width: 200px;
        max-width: 200px;
        background-color: ${props => props.theme.palette.secondary.main};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        &:hover {
            .cog {
                visibility: visible;
                opacity: 1;
            }
        }
    `,
    BrandLogo: styled("img")`
        width: 120px;
        aspect-ratio: 1.1;
        margin-bottom: 20px;
    `,
    BrandLogoPlaceholder: styled("div")`
        min-height: 120px;
        min-width: 120px;
        border-radius: 75px;
        margin-bottom: 20px;
        background-color: gray;
        display: flex;
        justify-content: center;
        align-items: center;
    `,
};
