import { Grid, styled } from "@mui/material";

export const Styled = {
    Form: styled("form")``,
    Content: styled(Grid)`
        background-color: ${props => props.theme.palette.secondary.dark};
        border: 1px solid ${props => props.theme.palette.secondary.main};
        border-radius: 16px;
        padding: 32px;
        transition: border-color 0.5s ease, color 0.5s ease;

        &:hover {
            border-color: ${props => props.theme.palette.secondary.light};
        }

        ${props => props.theme.breakpoints.down("md")} {
            border-radius: unset;
        }
    `,
};
