import { useQueryOrganizations } from "@/hooks/queries";
import React from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Typography } from "@mui/material";
import { Styled } from "./styles";

const Home: React.FC = () => {
    const navigate = useNavigate();
    const { data, isLoading } = useQueryOrganizations();

    const onSelect = (id: string) => {
        navigate(`/organization/${id}`);
    };

    return (
        <Styled.Container>
            <Typography variant="h1">Organizations</Typography>
            {isLoading ? (
                <CircularProgress />
            ) : (
                <Styled.List>
                    {data?.map(i => {
                        return (
                            <Styled.ListItem key={i._id} onClick={onSelect.bind(this, i._id)}>
                                <Typography variant="h4">{i.title}</Typography>
                                <Typography variant="body2">{i.companyId}</Typography>
                            </Styled.ListItem>
                        );
                    })}
                </Styled.List>
            )}
        </Styled.Container>
    );
};

export default Home;
