import { apiRequest } from "@/api";
import { useAdmin } from "@/context/admin";
import { log } from "@/utils/logger";
import { useQuery } from "@tanstack/react-query";

interface Organization {
    _id: string;
    title: string;
    companyId: string;
    createdAt?: string;
    updatedAt?: string;
}

const useQueryOrganizations = () => {
    const authenticated = useAdmin(state => state.authenticated);

    const getOrganizations = async () => {
        try {
            const organizations = await apiRequest<Array<Organization>>("GET", "/organizations");
            return organizations;
        } catch (e) {
            log.error(e);
        }
    };

    return useQuery({
        queryKey: ["organizations"],
        queryFn: getOrganizations,
        enabled: authenticated,
    });
};

export default useQueryOrganizations;
