import { useQueryBrands, useQueryOrganization } from "@/hooks/queries";
import React from "react";
import constant from "@/utils/constant";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { Styled } from "./styles";
import { shorten } from "@/utils/helpers";
import { isArrayWithData } from "@/utils/validations";
import { OrganizationForm, SubscriptionForm, UsageForm } from "@/components/organization";

const Organization: React.FC = () => {
    const { data: queryOrganization, isLoading: loadingOrganization } = useQueryOrganization();
    const { data: queryBrands, isLoading: loadingBrands } = useQueryBrands();
    const navigate = useNavigate();

    if (loadingOrganization || loadingBrands) {
        return (
            <Styled.Container>
                <CircularProgress />
            </Styled.Container>
        );
    }

    if (!queryOrganization || !queryBrands) {
        return null;
    }

    const { organization, subscription } = queryOrganization;

    const onSelectBrand = (id: string) => {
        navigate(`/organization/${organization._id}/brand/${id}/products`);
    };

    return (
        <Styled.Container>
            <Grid item xs={12}>
                <Typography variant="h1">{organization.title}</Typography>
            </Grid>
            <Grid item container xl={9} gap={constant.GRID_SPACING}>
                <OrganizationForm organization={organization} />
                <SubscriptionForm subscription={subscription} />
                <UsageForm usage={subscription.usage} />
                <Grid item xs={12}>
                    <Typography variant="h2" margin="12px 0px">
                        Brands
                    </Typography>
                </Grid>
                {isArrayWithData(queryBrands) ? (
                    <Styled.Brands>
                        {queryBrands.map(i => {
                            const media = i.media;
                            const title = i.title;
                            const url: string | undefined = media && media.variants?.[3]?.url;

                            return (
                                <Styled.BrandItem
                                    key={i._id}
                                    onClick={onSelectBrand.bind(this, i._id)}
                                >
                                    {url ? (
                                        <Styled.BrandLogo src={url} alt={i.title} />
                                    ) : (
                                        <Styled.BrandLogoPlaceholder>
                                            <Typography
                                                variant="h2"
                                                marginBottom={0}
                                                textTransform="uppercase"
                                            >
                                                {title[0]}
                                            </Typography>
                                        </Styled.BrandLogoPlaceholder>
                                    )}
                                    <Typography
                                        textAlign="center"
                                        width="100%"
                                        sx={{ wordWrap: "break-word" }}
                                    >
                                        {shorten(title, 35)}
                                    </Typography>
                                </Styled.BrandItem>
                            );
                        })}
                    </Styled.Brands>
                ) : (
                    <Typography>No brands found</Typography>
                )}
            </Grid>
        </Styled.Container>
    );
};

export default Organization;
