import { apiRequest } from "@/api";
import { useAdmin } from "@/context/admin";
import { Media } from "@/types/generated/brand";
import { log } from "@/utils/logger";
import { isDefined } from "@/utils/validations";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

interface Brand {
    _id: string;
    title: string;
    media: Media;
}

const useQueryBrands = () => {
    const authenticated = useAdmin(state => state.authenticated);
    const { oid } = useParams();
    const enabled = authenticated && isDefined(oid);

    const getBrands = async () => {
        try {
            const data = await apiRequest<Array<Brand>>("GET", `/organizations/${oid}/brands`);
            return data;
        } catch (e) {
            log.error(e);
        }
    };

    return useQuery({ queryKey: ["brands", oid], queryFn: getBrands, enabled });
};

export default useQueryBrands;
